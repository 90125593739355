import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";
import { useTranslation } from 'react-i18next';

function Works() {
  const { t, i18n } = useTranslation();

  const experienceData = [
    {
      id: 1,
      title: t('workPage.work1.title'),
      years: t('workPage.work1.years'),
      content: t('workPage.work1.content'),
      att:[]
    },
    {
      id: 2,
      title: t('workPage.work2.title'),
      years: t('workPage.work2.years'),
      content: t('workPage.work2.content'),
      att:[]
    },
    {
      id: 3,
      title: t('workPage.work3.title'),
      years: t('workPage.work3.years'),
      content: t('workPage.work3.content'),
      att:[]
    },
  ];
  return (
    <section id="works">
      <div className="container">
        <Pagetitle title={t('section.works')} />
        <div className="row">
          <div className="col-md-12">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Works;
