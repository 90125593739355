import React, {Suspense} from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import "./App.scss";

function App() {
  return (
    <Suspense fallback="">
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <Homepage />
        </Route>
      </Switch>
    </BrowserRouter>
    </Suspense>
  );
}

export default App;
