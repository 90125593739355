import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

function Portfolio({ portfolio }) {
  const { category, title, image, popupLink, link } = portfolio;
  const [toggler, setToggler] = useState(false);

  const handleLightbox = (e) => {
    if (!link) {
      e.preventDefault();
      setToggler(!toggler);
    }
  };

  const handleIcon = () => {
    var string = "";//= <div class="col-md-4 col-sm-6 grid-item">;
    if (link) {
      string = <i className="icon-link"/>;
      string = <i className="icon-link"></i>;
      //string = string + </div>;

      return string;
    } 
    if (popupLink) {
      if (popupLink.length > 1) {
        if (popupLink.toString().match(/youtube/g)) {
          return <i className="icon-camrecorder"></i>;
        }
        return <i className="icon-picture"></i>;
      }  if (popupLink.toString().match(/youtube/g)) {
        return <i className="icon-camrecorder"></i>;
      }  {
        return <i className="icon-magnifier-add"></i>;
      }
    }
    return <i className="icon-magnifier-add"></i>;
  };
  return (
    <>
      <a
        href={link ? link : "!#"}
        className="work-image"
        onClick={handleLightbox}
      >
        <div className="portfolio-item rounded shadow-dark">
          <div className="details">
            <span className="term text-capitalize" id='detailCat'>{category}</span>
            <h4 className="title">{title}</h4> 
            {/* {handleIcon()} */}
            <div class="col-md-12">
            <span className="more-button"><i className="icon-link"/> </span>
            </div>
          </div>
          <div className="thumb">
            {/* <img src= alt="Portfolio-title" /> */}
            <img src={image} class="img-fluid img-thumbnail" alt="Portfolio-title"/>
            <div className="mask"></div>
          </div>
        </div>
      </a>
      { popupLink && <FsLightbox toggler={toggler} sources={popupLink} />}
    </>
  );
}

export default Portfolio;
