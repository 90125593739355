import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Logo({ logoSource, bootstrapNav }) {
  const { t, i18n } = useTranslation();
  if (bootstrapNav) {
    return (
      <Link className="navbar-brand" to="/">
          <h6 className="mb-2 mt-0">{t('profile')}</h6>
      </Link>
    );
  }

  return (
    <div className="site-logo">
        <h5><a href="/">{t('profile')}</a></h5>
    </div>
  );
}

export default Logo;
