import React, { useEffect } from "react";
import { Link } from "react-scroll";
import Logo from "../elements/Logo";
import { useTranslation } from 'react-i18next';

function Header({ light, logoSource, toggleMenu, headerToggler }) {
  const handleClasses = () => {
    let classes = "desktop-header-1 d-flex align-items-start flex-column";
    if (light & toggleMenu) {
      classes += " light open";
    } else if (toggleMenu) {
      classes += " open";
    } else if (light) {
      classes += " light";
    }
    return classes;
  };
  const handleMobileClasses = () => {
    let classes = "mobile-header-1";
    if (light & toggleMenu) {
      classes += " light open";
    } else if (toggleMenu) {
      classes += " open";
    } else if (light) {
      classes += " light";
    }
    return classes;
  };

  const { t, i18n } = useTranslation();

  const changeLanguage = async (lng) => {
    await i18n.changeLanguage(lng);
    window.location.reload(true);
    // switch (lng) {
    //   case 'en': document.getElementById('language').innerHTML = ' ' + t('language.english');


    //     break;
    //   case 'it': document.getElementById('language').innerHTML = ' ' + t('language.italian');
    //     break;
    //   default:
    //     break;
    // }
  };

  return (
    <>
      <header className={handleMobileClasses()}>
        <div className="container">
          <div className="menu-icon d-inline-flex mr-4">
            <button onClick={headerToggler}>
              <span></span>
            </button>
          </div>
        </div>
      </header>
      <header className={handleClasses()}>
        <Logo logoSource={logoSource} />
        <nav>
          <ul className="vertical-menu scrollspy">
            <li>
              <Link
                activeClass="active"
                to="section-home"
                spy={true}
                smooth={true}
                duration={500}
              >
                <i id='sectionHome' className="icon-home"></i> {t('section.home')}
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="section-about"
                spy={true}
                smooth={true}
                duration={500}
              >
                <i className="icon-user-following"></i>{t('section.about')}
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="section-experiences"
                spy={true}
                smooth={true}
                duration={500}
              >
                <i className="icon-graduation"></i>{t('section.experience')}
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="section-works"
                spy={true}
                smooth={true}
                duration={500}
              >
                <i className="icon-briefcase"></i>{t('section.works')}
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="section-projects"
                spy={true}
                smooth={true}
                duration={500}
              >
                <i className="icon-layers"></i>{t('section.projects')}
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="section-contact"
                spy={true}
                smooth={true}
                duration={500}
              >
                <i className="icon-bubbles"></i>{t('section.contact')}
              </Link>
            </li>
          </ul>
        </nav>

        <div className="footer">
          <div class="dropdown">
            <a class="dropdown-toggle" type="button" data-toggle="dropdown"><i className="fas fa-globe fa-xs">
              <span className='language' id='language'>
                {' '} {i18n.language == 'en' ? t('language.english') : t('language.italian')}
              </span>
            </i></a>
            <ul class="dropdown-menu" type='button'>
              <a class="dropdown-item" onClick={() => changeLanguage('it')}><span class="flag-icon flag-icon-it" />{' '} {t('language.italian')}</a>
              <a class="dropdown-item" onClick={() => changeLanguage('en')}><span class="flag-icon flag-icon-us" />{' '} {t('language.english')}</a>
            </ul>
          </div>
          <span className="copyright">
            &copy; {new Date().getFullYear()} {t('profile')}.
          </span>
        </div>
      </header>
    </>
  );
}

export default Header;
