import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";
import { useTranslation } from 'react-i18next';

function Experiences() {
  const { t, i18n } = useTranslation();

  const educationData = [
    {
      id: 1,
      title: t('experiencePage.edu1.title'),
      years: t('experiencePage.edu1.years'),
      content: t('experiencePage.edu1.content'),
      att: [["/images/CVPdf/Tesi_Francesco_Rispoli.pdf", t('nameAttachment.thesis'), "fas fa-file-download"],
      ]
    },
    {
      id: 2,
      title: t('experiencePage.edu2.title'),
      years: t('experiencePage.edu2.years'),
      content: t('experiencePage.edu2.content'),
      att: []
    },
    {
      id: 3,
      title: t('experiencePage.edu3.title'),
      years: t('experiencePage.edu3.years'),
      content: t('experiencePage.edu3.content'),
      att: []
    },
    {
      id: 4,
      title: t('experiencePage.edu4.title'),
      years: t('experiencePage.edu4.years'),
      content: t('experiencePage.edu4.content'),
      att: []
    },
    {
      id: 5,
      title: t('experiencePage.edu5.title'),
      years: t('experiencePage.edu5.years'),
      content: t('experiencePage.edu5.content'),
      att: []
    },
  ];
  //console.log(educationData[1].att.nameAtt[0]);
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title={t('section.experience')} />
        <div className="row">
          <div className="col-md-12">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

export default Experiences;
