import { t } from "i18next";
import React, { useState, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";
import Portfolio from "../elements/Portfolio";
import { useTranslation } from 'react-i18next';
const foundation = "images/projects/iOS/foundation";
const academy = "images/projects/iOS/academy";
const virtualreal = "images/projects/realtavirtuale";


function Projects() {
  const { t, i18n } = useTranslation();

  const filters = [
    {
      id: 1,
      text: t('projectPage.filters.everything'),
    },
    {
      id: 2,
      text: t('projectPage.filters.iosapp'),
    },
    {
      id: 3,
      text: t('projectPage.filters.hybridapp'),
    },
    {
      id: 4,
      text: t('projectPage.filters.others'),
    },
  ];
  
  const allData = [
    {
      id: 1,
      title: "Inspirea",
      category: t('projectPage.filters.hybridapp'),
      image: academy + "/inspireaHybrid.png",
      popupLink: [
        academy + "/inspireaHybrid.png",
        academy + "/InspireaHybridVideo.mov",
      ],
    },
    {
      id: 2,
      title: "Monstersory",
      category: t('projectPage.filters.iosapp'),
      image: academy + "/monstersory-mockup.jpg",
      popupLink: [
        academy + "/monstersory-mockup.jpg",
        academy + "/monstersory-apple-tv.jpg",
        academy + "/monstersory-iphone.mp4",
        academy + "/monstersory-tv.mp4"
      ],
    },
    {
      id: 3,
      title: "Money Circle",
      category: t('projectPage.filters.iosapp'),
      image: academy + "/money circle.jpg",
      popupLink: [
        academy + "/money circle.jpg",
        academy + "/moneycirclewatch.png",
        
        
      ],
    },
    {
      id: 4,
      title: "Swipe It",
      category: t('projectPage.filters.iosapp'),
      image: foundation + "/swipeIt/swipe-it.jpg",
      popupLink: [
        foundation + "/swipeIt/swipe-it.jpg",
        foundation + "/swipeIt/swipe-it.mp4",
        foundation + "/swipeIt/swipe-it-remote-control.jpg",
        
      ],
    },
    {
      id: 5,
      title: "Planet seekers",
      category: t('projectPage.filters.iosapp'),
      image: foundation + "/Planet seekers/planet-seekers.jpg",
      popupLink: [
        foundation + "/Planet seekers/planet-seekers.jpg",
        foundation + "/Planet seekers/planet-seekers.mp4",
        foundation + "/Planet seekers/planetSeekerWatch.png",
        foundation + "/Planet seekers/video watch.mov",
      ],
    },
    {
      id: 6,
      title: "Take It",
      category: t('projectPage.filters.iosapp'),
      image: foundation + "/Take it/MOCKUPIPAD.png",
      popupLink: [
        foundation + "/Take it/MOCKUPIPAD.png",
        foundation + "/Take it/Francesco_Rispoli_TakeIt.MP4",
      ],
    },
    {
      id: 7,
      title: "Inspirea",
      category: t('projectPage.filters.iosapp'),
      image: academy + "/inspirea.jpg",
      popupLink: [
        academy + "/inspirea.jpg",
        academy + "/InspireaVideo.mov"
        
      ],
    },
    {
      id: 8,
      title: "Flids",
      category: t('projectPage.filters.iosapp'),
      image: foundation + "/flids/flids.jpg",
      popupLink: [
        foundation + "/flids/flids.jpg",
        foundation + "/flids/flids.mov",
        foundation + "/flids/flids.mp4",
      ],
    },
    {
      id: 9,
      title: "NazistTrap",
      category: t('projectPage.filters.others'),
      image: virtualreal + "/Nazist Trap.png",
      popupLink: [
        virtualreal + "/Nazist Trap.png",
        virtualreal + "/VideoDimostrativo_Francesco_Rispoli_RV_2020-21.mov",
      ],
    },
    {
      id: 10,
      title: "IDennis",
      category: t('projectPage.filters.iosapp'),
      image: academy + "/idennis.jpg",
      popupLink: [
        academy + "/idennis.jpg",
      ],
    },
    
  ];
  const [getAllItems] = useState(allData);
  const [dataVisibleCount, setDataVisibleCount] = useState(12);
  const [dataIncrement] = useState(8);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  const [noMorePost, setNoMorePost] = useState(false);
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    console.log(language);
    setActiveFilter(filters[0].text.toLowerCase());
    setVisibleItems(getAllItems.filter((item) => item.id <= 12));
  }, [getAllItems]);

  const handleChange = (e) => {
    e.preventDefault();
    setActiveFilter(e.target.textContent.toLowerCase());
    let tempData;
    if (e.target.textContent.toLowerCase() === filters[0].text.toLowerCase()) {
      tempData = getAllItems.filter((data) => data.id <= dataVisibleCount);
    } else {
      tempData = getAllItems.filter(
        (data) => 
          data.category === e.target.textContent.toLowerCase() &&
          data.id <= dataVisibleCount
      );
    }
    setVisibleItems(tempData);
  };

  const handleLoadmore = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;
    if (dataVisibleCount > getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      if (activeFilter === filters[0].text.toLowerCase()) {
        console.log("they are same");
        setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
      } else {
        setVisibleItems(
          getAllItems.filter(
            (data) => data.category === activeFilter && data.id <= tempCount
          )
        );
      }
    }
  };

  return (
    <section id="works">
      <div className="container">
        <Pagetitle title={t('section.projects')} />
        {/* Start Portfolio Filters */}
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <ul className="portfolio-filter list-inline">
            {filters.map((filter) => (
              <li className="list-inline-item" key={filter.id}>
                <button
                  onClick={handleChange}
                  className={
                    filter.text.toLowerCase() === activeFilter
                      ? "text-capitalize current"
                      : "text-capitalize"
                  }
                >
                  {filter.text}
                </button>
              </li>
            ))}
          </ul>
        </ScrollAnimation>
        {/* End Portfolio Filters */}

        {/* Start Portfolio Items */}
        <div className="row portfolio-wrapper">
          {visibleItems.map((item) => (
            <div className="col-md-3 col-sm-6 grid-item" key={item.id}>
              <Portfolio portfolio={item} />
            </div>
          ))}
        </div>
        {/* End Portfolio Items */}

        <div className="load-more text-center mt-4">
          <button
            className="btn btn-default"
            onClick={handleLoadmore}
            disabled={noMorePost ? "disabled" : null}
          >
            {noMorePost ? (
              t('projectPage.noMore')
            ) : (
              <span>
                <i className="fas fa-spinner"></i> {t('projectPage.loadMore')}
              </span>
            )}
          </button>
        </div>
      </div>
    </section>
  );
}

export default Projects;
