import React from "react";
import TrackVisibility from "react-on-screen";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";
import { useTranslation } from 'react-i18next';


function About() {
  const { t, i18n } = useTranslation();

  const aboutContent = {
    name: "Francesco",
    avatarImage: "/images/avatar-1.png",
    content: t('aboutPage.description'),
  };
  
  const progressData = [
    {
      id: 1,
      title: t('aboutPage.titleFirstSkill'),
      percantage: 80,
      progressColor: "#FFD15C",
    },
    {
      id: 2,
      title: t('aboutPage.titleSecondSkill'),
      percantage: 70,
      progressColor: "#FF4C60",
    },
    {
      id: 3,
      title: t('aboutPage.titleThirdSkill'),
      percantage: 80,
      progressColor: "#6C6CE5",
    },
  ];
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title={t('section.about')} />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img src={aboutContent.avatarImage} alt={aboutContent.name} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                {aboutContent.content.split('\n').map(c => {
                  return ( <p class="description"> {c} </p>) 
                   })}
                   
                  <div className="mt-3">
                      {i18n.language == "en"?
                      (<a href={"/images/CVPdf/CV_Francesco_Rispoli_en.pdf"} className="btn btn-default" download>
                        {t('aboutPage.downloadCV')}
                      </a>)
                      :(<a href={"/images/CVPdf/CV_Francesco_Rispoli_it.pdf"} className="btn btn-default" download>
                      {t('aboutPage.downloadCV')}
                    </a>)}
                  </div>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="70"></div>
      </div>
    </section>
  );
}

export default About;
