import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation, Trans } from 'react-i18next';

function Timeline({ education }) {
  const { t, i18n } = useTranslation();

  const { years, title, content, att } = education;
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      animateOut="fadeInOut"
      animateOnce={true}
    >
      {/* {console.log(att[0][0])} */}
      <div className="timeline-container">
        <div className="content">
          <h3 className="title">{title}</h3>
          <div class="d-flex justify-content-between">
            <div class="col-ms-6">
              <span className="time">{years}</span>
            </div>
            {att.length === 0 ? null : att.length === 1 ?
              <a type="button" href={att[0][0]} download><i className={att[0][2] + " fa-sm"}>
                <span className='attachment'> {t('timeline.attachment') + ": " + att[0][1]}
                </span>
              </i></a> :
              <div class="col-ms-6">
                <div class="ms-auto">
                  {/* <a href={"/images/CVPdf/keyforge"+i18n.language+".pdf"} className="btn btn-default" download> */}
                  <a class="dropdown-toggle" type="button" data-toggle="dropdown"><i className="fas fa-paperclip fa-sm">
                    <span className='attachment'> {t('timeline.attachments')}
                    </span>
                  </i></a>
                  <ul class="dropdown-menu">
                    {Object.values(att).map((item) => (
                      <a class="dropdown-item" href={item[0]} download><span class={att[0][2]} />{" " + item[1]}</a>
                    ))}
                  </ul>
                </div>
              </div>
            }
          </div>
          {content.split('\n').map(c => {
            return (<p class="description"> {c} </p>) 
          })}
        </div>
      </div>
    </ScrollAnimation>
  );
}

export default Timeline;
